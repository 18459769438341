import React, { Component } from "react"
import { Row, Col, Card, Container, CardHeader, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";

import api from '../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Protocolos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Protocolos", link: "/protocolos-doencas" },
        { title: "Documentos", link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      productData: [],
      visible: false,
      modal_standard: false,
      modal_apagar: false,
      filtro: '',
      queryParams: '',
      linkPDF: '',
      nome: ''
    }

    this.controlarModal = this.controlarModal.bind(this);
  };

  async buscarDados() {
    const options = this.getOptions();
    let inputPesquisar = document.getElementById('inputPesquisar').value;

    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);
    const query = params.get('q');
    this.state.queryParams = query;

    const { data } = await api.get(`/protocolos?q=${query}&inputPesquisar=${inputPesquisar}`, options);

    this.setState({
      productData: data
    });
  }

  controlarModal() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }

  async controlarModalPDF(pdf, protocolo) {
    this.state.linkPDF = pdf;
    this.state.nome = protocolo;
    this.controlarModal();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  getOptions() {
    let obj = '';
    if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
    }

    const options = {
      headers: {"Authorization" : `Bearer ${obj.token}`}
    }

    return options;
  }

  componentDidMount() {
    this.buscarDados();
    document.getElementById('inputPesquisar').focus();
  }

  render() {

    return (
      <React.Fragment>
          <div className="page-content">
              <Container fluid>

              <Breadcrumbs title={`Protocolos ${this.state.queryParams}`} breadcrumbItems={this.state.breadcrumbItems} />
              <Row>
                <Col md="12">
                  <div className="">
                    <div className="search-box ms-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control rounded"
                          id="inputPesquisar"
                          placeholder="Pesquisar..."
                          onChange={() => this.buscarDados()}
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                  {
                    this.state.productData?.map(item =>
                      <Col lg={4}>
                          <Link to="#" onClick={() => this.controlarModalPDF(item.link, item.protocolo)}>
                              <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                                  <CardHeader className="bg-transparent">
                                      <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.protocolo }</h5>
                                  </CardHeader>
                              </Card>
                          </Link>
                      </Col>
                    )
                  }
              </Row>
              <Modal
                size="xl"
                isOpen={this.state.modal_standard}
                toggle={this.controlarModal}

                className="modal-fullscreen"
              >
                <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
                  {this.state.nome}
                </ModalHeader>
                <ModalBody>
                  
                <iframe src={this.state.linkPDF} width="100%" height="700px" allow="autoplay" title='pdf'></iframe>
                
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.controlarModal}
                    color="light"
                    className="waves-effect"
                  >
                      Fechar
                  </Button>
                </ModalFooter>
              </Modal>
              </Container> 
          </div>
      </React.Fragment>
    )
  }
}

export default Protocolos;