import React, { Component } from "react";

import { Card, CardImg, Row } from "reactstrap";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";
import img4 from "../../assets/images/parceiros/itaci.png";

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imagem: ''
        };

    }

    componentDidMount() {
        this.initMenu();
        this.imagensDinamicas();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
    }

    
    getLink(image) {
    //  if(image === img1) {
    //     return "https://www.takeda.com/pt-br"
    //  }
    //  if(image === img2) {
    //     return "https://www.amgen.com.br/"
    //  }
    //  if(image === img3) {
    //     return "https://www.roche.com.br"
    //  }
     if(image === img4) {
        return "https://www.itaci.org.br/"
     }
    }

    imagensDinamicas() {
    //   var arr = [img1, img2,
    //     img3, img4];
    //    this.state.imagem = (arr[Math.floor(Math.random() * arr.length)]);

       this.state.imagem = img4;
      }
        
    

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link to="/dashboard">
                                <i className="ri-dashboard-line"></i>
                                <span className="ms-1">Menu</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/protocolos-doencas">
                                <i className="ri-list-check-2"></i>
                                <span className="ms-1">Protocolos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/drogas">
                                <i className="ri-hand-heart-fill"></i>
                                <span className="ms-1">Drogas</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/procedimentos">
                                <i className="ri-file-list-2-line"></i>
                                <span className="ms-1">Procedimentos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/exames-de-diagnostico">
                                <i className="mdi mdi-speedometer-slow mdi-needle"></i>
                                <span className="ms-1">Exames de diagnóstico</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/nutricao-categorias">
                                <i className="ri-cake-3-line"></i>
                                <span className="ms-1">Nutrição</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/eletrolitos-categoria">
                                <i className="mdi mdi-speedometer-slo mdi-pill"></i>
                                <span className="ms-1">Eletrólitos</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="has-arrow waves-effect" to="/#">
                                <i className="ri-drop-fill"></i>
                                <span className="ms-1">Hemoterapia</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hemocomponentes?q=Hemocomponentes">
                                        Hemocomponentes
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cuidados-especiais?q=CuidadosEspeciais">
                                        Cuidados Especiais
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Reacoes-Transfusional?q=ReacoesTransfusional">
                                        Reações Transfusionais
                                    </Link>
                                </li>
                                <li>

                                <Link className="has-arrow waves-effect" to="/#">
                                
                                    <span className="ms-1"><strong>Coleta de células tronco hematopoéticas</strong></span>
                                </Link>
                                <ul className="sub-menu">
                                <li>
                                        <Link to="/mobilizacao?q=mobilizacao">
                                        Mobilização
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/coleta-celulas-medula-ossea?q=coleta-celulas-medula-ossea">
                                        Coleta de células da medula óssea
                                        </Link>
                                    </li>
                                </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/parceiros">
                                <i className="ri-team-line"></i>
                                <span className="ms-1">Parceiros</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/procedimentos">
                                <i className="ri-file-list-2-line"></i>
                                <span className="ms-1">Procedimentos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/suporte">
                                <i className="ri-arrow-left-right-line"></i>
                                <span className="ms-1">Suporte transfusional</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                
                <Row style={{ padding: '20px', width: '225px', paddingLeft: '40px' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Parceiros
                    <Card style={{ borderRadius: '15px' }}>
                        <Link 
                            to={{ pathname: this.getLink(this.state.imagem) }}
                            target="_blank"
                        >
                            <CardImg top className="img-fluid" src={this.state.imagem} alt="Takeda" style={{ borderRadius: '15px' }}/>
                        </Link>
                    </Card>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
})(withNamespaces()(SidebarContent)));
