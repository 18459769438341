import React, { Component } from "react"
import { Row, Col, Card, Container, CardHeader, Modal, Button, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";

import api from '../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom";

class Nutricao extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Nutrição", link: "/nutricao-categorias" },
        { title: "Detalhes", link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      productData: [],
      visible: false,
      modal_standard: false,
      modal_pdf: false,
      modal_apagar: false,
      filtro: '',
      categorias: '',
      nomes: '',
      nome_comercial: '',
      introducao: '',
      composicao: '',
      descricao: '',
      indicacoes: '',
      dose: '',
      contraindicacao: '',
      conservacao: '',
      alimentos_permitidos: '',
      alimentos_contraindicados: '',
      alimentacao_foradecasa: '',
      cuidados_higiene_dosalimentos: '',
      consideracoes_especiais: '',
      paramsFiltro: ''
    }

    this.controlarModal = this.controlarModal.bind(this);
    this.controlarModalPDF = this.controlarModalPDF.bind(this);
  };

  controlarModal() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }

  async controlarModalPDF(pdf, nome) {
    this.state.linkPDF = pdf;
    this.state.nome = nome;
    this.setState(prevState => ({
      modal_pdf: !prevState.modal_pdf
    }));
    this.removeBodyCss();
  }

  async buscarDados() {
    const options = this.getOptions();

    let inputPesquisar = document.getElementById('inputPesquisar').value;

    this.state.paramsFiltro = this.getParams();

    const { data } = await api.get(`/nutricao?q=${this.state.paramsFiltro}&inputPesquisar=${inputPesquisar}`, options);

    this.setState({
      productData: data
    });
  }

  getOptions() {
    let obj = '';
    if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
    }

    const options = {
      headers: {"Authorization" : `Bearer ${obj.token}`}
    }

    return options;
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  setValueId(nomeId, valor) {
    document.getElementById(nomeId).value = valor;
  }

  getParams() {
    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);
    return params.get('q');
  }

  async controlarModalEdicao(idItem) {
    const options = this.getOptions();
    await api.get(`/nutricao/${idItem}`, options)
    .then(({ data })=> {

      this.controlarModal();

      setTimeout(() => {
        this.setValueId('idItem', idItem);
        this.setState({ nomes: data.nomes });
        this.setState({ categorias: data.categorias });
        this.setState({ nome_comercial: data.nome_comercial });
        this.setState({ introducao: data.introducao });
        this.setState({ composicao: data.composicao });
        this.setState({ descricao: data.descricao });
        this.setState({ indicacoes: data.indicacoes });
        this.setState({ dose: data.dose });
        this.setState({ contraindicacao: data.contraindicacao });
        this.setState({ conservacao: data.conservacao });
        this.setState({ alimentos_permitidos: data.alimentos_permitidos });
        this.setState({ alimentos_contraindicados: data.alimentos_contraindicados });
        this.setState({ alimentacao_foradecasa: data.alimentacao_foradecasa });
        this.setState({ cuidados_higiene_dosalimentos: data.cuidados_higiene_dosalimentos });
        this.setState({ consideracoes_especiais: data.consideracoes_especiais });
      }, 500);
    });

  }

  format(text) {
    return text.split('\n').map(str => <p>{str}</p>);
  }

  componentDidMount() {
    this.buscarDados();

    document.getElementById('inputPesquisar').focus();

    this.setState({
      filtro: this.getParams()
    });
  }

  render() {

    return (
      <React.Fragment>
          <div className="page-content">
              <Container fluid>

              <Breadcrumbs title={`Nutrição - ${this.state.paramsFiltro}`} breadcrumbItems={this.state.breadcrumbItems} />
              <Row>
                <Col md="12">
                  <div className="">
                    <div className="search-box ms-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control rounded"
                          id="inputPesquisar"
                          placeholder="Pesquisar..."
                          onChange={() => this.buscarDados()}
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                  {
                    this.state.productData?.map(item =>
                   <>
                      {item.link ? (
                        <Col lg={4}> 
                          <Link to="#" onClick={() => this.controlarModalPDF(item.link, item.nomes)}>
                            <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                              <CardHeader className="bg-transparent">
                                <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nomes }</h5>
                              </CardHeader>
                            </Card>
                          </Link>
                       </Col>
                      ) : (
                          <Col lg={4}>
                            <Link to="#" onClick={() => this.controlarModalEdicao(item.idnutricao)}>
                                <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                                    <CardHeader className="bg-transparent">
                                        <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nomes }</h5>
                                    </CardHeader>
                                </Card>
                            </Link>
                         </Col>
                      )}
                    </>
                    )
                  }
              </Row>
              <Modal
                size="xl"
                isOpen={this.state.modal_pdf}
                toggle={this.controlarModalPDF}

                className="modal-fullscreen"
              >
                <ModalHeader toggle={() => this.setState({ modal_pdf: false })}>
                {this.state.nome}
                </ModalHeader>
                <ModalBody>
                  
                <iframe src={this.state.linkPDF} width="100%" height="700px" allow="autoplay" title='pdf'></iframe>
                
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.controlarModalPDF}
                    color="light"
                    className="waves-effect"
                  >
                      Fechar
                  </Button>
                </ModalFooter>
              </Modal>
              </Container> 
          </div>
          <Modal
            size="xl"
            isOpen={this.state.modal_standard}
            toggle={this.controlarModal}
          >
            <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              {this.state.nomes}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                  <Input id="idItem" type="text" style={{ display: 'none' }}/>
              </Row>

              {this.state.categorias ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Categoria: <span style={{ fontWeight: 300 }}>{this.format(this.state.categorias)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.nome_comercial ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Nome Comercial: <span style={{ fontWeight: 300 }}>{this.format(this.state.nome_comercial)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.descricao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Descrição: <span style={{ fontWeight: 300 }}>{this.format(this.state.descricao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.introducao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Introdução: <span style={{ fontWeight: 300 }}>{this.format(this.state.introducao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.dose ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Dose: <span style={{ fontWeight: 300 }}>{this.format(this.state.dose)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.indicacoes ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Indicações: <span style={{ fontWeight: 300 }}>{this.format(this.state.indicacoes)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.contraindicacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Contra indicação: <span style={{ fontWeight: 300 }}>{this.format(this.state.contraindicacao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.cuidados_higiene_dosalimentos ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Cuidados e higiene dos alimentos: <span style={{ fontWeight: 300 }}>{this.format(this.state.cuidados_higiene_dosalimentos)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.alimentos_permitidos ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Alimentos permitidos: <span style={{ fontWeight: 300 }}>{this.state.alimentos_permitidos}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.alimentos_contraindicados ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Alimentos contraindicados: <span style={{ fontWeight: 300 }}>{this.format(this.state.alimentos_contraindicados)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.alimentacao_foradecasa ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Alimentação fora de casa: <span style={{ fontWeight: 300 }}>{this.format(this.state.alimentacao_foradecasa)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.conservacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Conservação: <span style={{ fontWeight: 300 }}>{this.format(this.state.conservacao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.consideracoes_especiais ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Considerações especiais : <span style={{ fontWeight: 300 }}>{this.format(this.state.consideracoes_especiais)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.composicao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Composição: <span style={{ fontWeight: 300 }}>{this.format(this.state.composicao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.hidratacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Hidratação: <span style={{ fontWeight: 300 }}>{this.format(this.state.hidratacao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={this.controlarModal}
                color="light"
                className="waves-effect"
              >
                  Fechar
              </Button>
            </ModalFooter>
          </Modal>
      </React.Fragment>
    )
  }
}

export default Nutricao;