import React, { Component } from "react"
import { Row, Col, Card, Container, CardHeader, Modal, Button, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";

import api from '../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom";

class Procedimentos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Menu", link: "/dashboard" },
        { title: "Detalhes", link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      productData: [],
      visible: false,
      modal_standard: false,
      modal_apagar: false,
      filtro: 'Procedimentos',
      nome:'',
      ativo:'',
      descricao:'',
      materiais:'',
      incremlinkento:'',
      indicacoes:'',
      locais_de_puncao:'',
      contraindicacao:'',
      time_out:'',
      paramentacao_e_assepsia:'',
      tecnicas:'',
      consideracoes_especiais:'',
      conservacao_e_envio_ao_laboratorio:''
    }

    this.controlarModal = this.controlarModal.bind(this);
  };

  controlarModal() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }

  async buscarDados() {
    const options = this.getOptions();
    let inputPesquisar = document.getElementById('inputPesquisar').value;

    const { data } = await api.get(`/procedimentos?q=${this.getParams()}&inputPesquisar=${inputPesquisar}`, options);

    this.setState({
      productData: data
    });
  }

  getOptions() {
    let obj = '';
    if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
    }

    const options = {
      headers: {"Authorization" : `Bearer ${obj.token}`}
    }

    return options;
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  setValueId(nomeId, valor) {
    document.getElementById(nomeId).value = valor;
  }

  getParams() {
    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);
    return params.get('q');
  }
  
  async controlarModalEdicao(idItem) {
    const options = this.getOptions();
    await api.get(`/procedimentos/${idItem}`, options)
    .then(({ data })=> {

      this.controlarModal();

      setTimeout(() => {
        this.setValueId('idItem', idItem);
        this.setState({ nome: data.nome });
        this.setState({ ativo: data.ativo });
        this.setState({ descricao: data.descricao });
        this.setState({ materiais: data.materiais });
        this.setState({ link: data.link });
        this.setState({ indicacoes: data.indicacoes });
        this.setState({ locais_de_puncao: data.locais_de_puncao });
        this.setState({ contraindicacao: data.contraindicacao });
        this.setState({ time_out: data.time_out });
        this.setState({ paramentacao_e_assepsia: data.paramentacao_e_assepsia });
        this.setState({ tecnicas: data.tecnicas });
        this.setState({ conservacao_e_envio_ao_laboratorio: data.conservacao_e_envio_ao_laboratorio });
        this.setState({ consideracoes_especiais: data.consideracoes_especiais });
      }, 500);
    });

  }

  format(text) {
    return text.split('\n').map(str => <p>{str}</p>);
  }

  componentDidMount() {
    this.buscarDados();

    document.getElementById('inputPesquisar').focus();

    this.setState({
      filtro: this.getParams()
    });
  }

  render() {

    return (
      <React.Fragment>
          <div className="page-content">
              <Container fluid>

              <Breadcrumbs title={'Procedimentos'} breadcrumbItems={this.state.breadcrumbItems} />
              <Row>
                <Col md="12">
                  <div className="">
                    <div className="search-box ms-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control rounded"
                          id="inputPesquisar"
                          placeholder="Pesquisar..."
                          onChange={() => this.buscarDados()}
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
              {
                    this.state.productData?.map(item =>
                   <>
                      {item.link ? (
                        <Col lg={4}> 
                          <Link to={{ pathname: item.link }} target="_blank">
                              <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                                  <CardHeader className="bg-transparent">
                                      <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nome }</h5>
                                  </CardHeader>
                              </Card>
                          </Link>
                       </Col>
                      ) : (
                          <Col lg={4}>
                            <Link to="#" onClick={() => this.controlarModalEdicao(item.idprocedimentos)}>
                                <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                                    <CardHeader className="bg-transparent">
                                        <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nome }</h5>
                                    </CardHeader>
                                </Card>
                            </Link>
                         </Col>
                      )}
                    </>
                    )
                  }
              </Row>
              </Container> 
          </div>
          <Modal
            size="xl"
            isOpen={this.state.modal_standard}
            toggle={this.controlarModal}
          >
            <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              {this.state.nome}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                  <Input id="idItem" type="text" style={{ display: 'none' }}/>
              </Row>
              
              {this.state.nome ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Nome: <span style={{ fontWeight: 300 }}>{this.format(this.state.nome)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.descricao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Descrição: <span style={{ fontWeight: 300 }}>{this.format(this.state.descricao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.materiais ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Materiais: <span style={{ fontWeight: 300 }}>{this.format(this.state.materiais)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.indicacoes ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Indicações: <span style={{ fontWeight: 300 }}>{this.format(this.state.indicacoes)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.contraindicacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Contraindicação: <span style={{ fontWeight: 300 }}>{this.format(this.state.contraindicacao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.locais_de_puncao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Locais de punção: <span style={{ fontWeight: 300 }}>{this.format(this.state.locais_de_puncao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.conservacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Conservasão: <span style={{ fontWeight: 300 }}>{this.format(this.state.conservacao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.time_out ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Procedimento: <span style={{ fontWeight: 300 }}>{this.format(this.state.time_out)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.paramentacao_e_assepsia ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Paramentação e assepsia: <span style={{ fontWeight: 300 }}>{this.format(this.state.paramentacao_e_assepsia)}</span></Label>
                </Row>
              </>) : (<></>)}
              {this.state.tecnicas ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Técnicas: <span style={{ fontWeight: 300 }}>{this.format(this.state.tecnicas)}</span></Label>
                </Row>
              </>) : (<></>)}
              {this.state.conservacao_e_envio_ao_laboratorio ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Conservação e envio ao laboratório: <span style={{ fontWeight: 300 }}>{this.format(this.state.conservacao_e_envio_ao_laboratorio)}</span></Label>
                </Row>
              </>) : (<></>)}
              {this.state.consideracoes_especiais ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Considerações especiais: <span style={{ fontWeight: 300 }}>{this.format(this.state.consideracoes_especiais)}</span></Label>
                </Row>
              </>) : (<></>)}
              
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={this.controlarModal}
                color="light"
                className="waves-effect"
              >
                  Fechar
              </Button>
            </ModalFooter>
          </Modal>
      </React.Fragment>
    )
  }
}

export default Procedimentos;