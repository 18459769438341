import React, { Component } from "react"
import { Row, Col, Card, Container, CardHeader, Modal, Button, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";

import api from '../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom";

class Eletrolitos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Eletrólitos", link: "/eletrolitos-categoria" },
        { title: "Detalhes", link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      productData: [],
      visible: false,
      modal_standard: false,
      modal_pdf: false,
      modal_apagar: false,
      filtro: '',
      ativo: '',
      descricao: '',
      categoria: '',
      nome: '',
      link: '', 
      composicao_concentracoes: '',
      consideracoes_especiais: '',
      interacao: '',
      tratamento_toxicidade: '', 
      meia_vida: '',
      quelantes: '',
      disturbios: '',
      correcoes_dose: '',
      valores_referencia: '',
      excrecao: '',
      mecanismo_acao: '',
      apresentacoes: '',
      nome_comercial: ''
    }

    this.controlarModal = this.controlarModal.bind(this);
    this.controlarModalPDF = this.controlarModalPDF.bind(this);
  };

  controlarModal() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }

  async controlarModalPDF(pdf, nome) {
    this.state.linkPDF = pdf;
    this.state.nome = nome;
    this.setState(prevState => ({
      modal_pdf: !prevState.modal_pdf
    }));
    this.removeBodyCss();
  }

  async buscarDados() {
    const options = this.getOptions();
    let inputPesquisar = document.getElementById('inputPesquisar').value;
    const { data } = await api.get(`/eletrolitos?q=${this.getParams()}&inputPesquisar=${inputPesquisar}`, options);

    this.setState({
      productData: data
    });
  }

  getOptions() {
    let obj = '';
    if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
    }

    const options = {
      headers: {"Authorization" : `Bearer ${obj.token}`}
    }

    return options;
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  setValueId(nomeId, valor) {
    document.getElementById(nomeId).value = valor;
  }

  getParams() {
    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);
    return params.get('q');
  }

  async controlarModalEdicao(idItem) {
    const options = this.getOptions();
    await api.get(`/eletrolitos/${idItem}`, options)
    .then(({ data })=> {

      this.controlarModal();

      setTimeout(() => {
        this.setValueId('idItem', idItem);
        this.setState({categoria: data.categoria });
        this.setState({ativo: data.ativo });
        this.setState({nome: data.nome });
        this.setState({composicao_concentracoes: data.composicao_concentracoes });
        this.setState({consideracoes_especiais: data.consideracoes_especiais });
        this.setState({interacao: data.interacao });
        this.setState({tratamento_toxicidade: data.tratamento_toxicidade });
        this.setState({meia_vida: data.meia_vida });
        this.setState({quelantes: data.quelantes });
        this.setState({disturbios: data.disturbios });
        this.setState({correcoes_dose: data.correcoes_dose });
        this.setState({valores_referencia: data.valores_referencia });
        this.setState({excrecao: data.excrecao });
        this.setState({mecanismo_acao: data.mecanismo_acao });
        this.setState({apresentacoes: data.apresentacoes });
        this.setState({nome_comercial: data.nome_comercial });
        this.setState({descricao: data.descricao });
      }, 500);
    });

  }

  format(text) {
    return text.split('\n').map(str => <p>{str}</p>);
  }

  componentDidMount() {
    this.buscarDados();
    
    document.getElementById('inputPesquisar').focus();

    this.setState({
      filtro: this.getParams()
    });
  }

  render() {

    return (
      <React.Fragment>
          <div className="page-content">
              <Container fluid>

              <Breadcrumbs title={`Eletrólitos - ${this.state.filtro}`} breadcrumbItems={this.state.breadcrumbItems} />
              <Row>
                <Col md="12">
                  <div className="">
                    <div className="search-box ms-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control rounded"
                          id="inputPesquisar"
                          placeholder="Pesquisar..."
                          onChange={() => this.buscarDados()}
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                  {
                    this.state.productData?.map(item =>
                   <>
                      {item.link ? (
                        <Col lg={4}> 
                          <Link to="#" onClick={() => this.controlarModalPDF(item.link, item.nome)}>
                            <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                              <CardHeader className="bg-transparent">
                                <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nome }</h5>
                              </CardHeader>
                            </Card>
                          </Link>
                       </Col>
                      ) : (
                          <Col lg={4}>
                            <Link to="#" onClick={() => this.controlarModalEdicao(item.ideletrolitos)}>
                                <Card outline color="info" className="border" style={{ borderRadius: '25px' }}>
                                    <CardHeader className="bg-transparent">
                                        <h5 style={{ padding: '25px', color: '#4aa3ff' }}>{ item.nome }</h5>
                                    </CardHeader>
                                </Card>
                            </Link>
                         </Col>
                      )}
                    </>
                    )
                  }
              </Row>
              <Modal
                size="xl"
                isOpen={this.state.modal_pdf}
                toggle={this.controlarModalPDF}

                className="modal-fullscreen"
              >
                <ModalHeader toggle={() => this.setState({ modal_pdf: false })}>
                {this.state.nome}
                </ModalHeader>
                <ModalBody>
                  
                <iframe src={this.state.linkPDF} width="100%" height="700px" allow="autoplay" title='pdf'></iframe>
                
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    onClick={this.controlarModalPDF}
                    color="light"
                    className="waves-effect"
                  >
                      Fechar
                  </Button>
                </ModalFooter>
              </Modal>
              </Container> 
          </div>
          <Modal
            size="xl"
            isOpen={this.state.modal_standard}
            toggle={this.controlarModal}
          >
            <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              {this.state.nome}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                  <Input id="idItem" type="text" style={{ display: 'none' }}/>
              </Row>
              
              {this.state.nome_comercial ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Nome Comercial: <span style={{ fontWeight: 300 }}>{this.format(this.state.nome_comercial)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.categoria ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Categoria: <span style={{ fontWeight: 300 }}>{this.format(this.state.categoria)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.descricao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Descrição: <span style={{ fontWeight: 300 }}>{this.format(this.state.descricao)}</span></Label>
                </Row>
              </>) : (<></>)}
              
              {this.state.consideracoes_especiais ? (<>
                <Row className="mb-3">
                  <Label className="form-label">consideraçoẽs especiais : <span style={{ fontWeight: 300 }}>{this.format(this.state.consideracoes_especiais)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.apresentacoes ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Apresentações : <span style={{ fontWeight: 300 }}>{this.format(this.state.apresentacoes)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.mecanismo_acao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Mecanismo de ação : <span style={{ fontWeight: 300 }}>{this.format(this.state.mecanismo_acao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.excrecao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Excreção : <span style={{ fontWeight: 300 }}>{this.format(this.state.excrecao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.valores_referencia ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Valores de referência : <span style={{ fontWeight: 300 }}>{this.format(this.state.valores_referencia)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.correcoes_dose ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Correções / Dose : <span style={{ fontWeight: 300 }}>{this.format(this.state.correcoes_dose)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.disturbios ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Disturbios : <span style={{ fontWeight: 300 }}>{this.format(this.state.disturbios)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.quelantes ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Quelantes : <span style={{ fontWeight: 300 }}>{this.format(this.state.quelantes)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.meia_vida ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Meia vida : <span style={{ fontWeight: 300 }}>{this.format(this.state.meia_vida)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.tratamento_toxicidade ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Tratamento da toxicidade : <span style={{ fontWeight: 300 }}>{this.format(this.state.tratamento_toxicidade)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.interacao ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Interação : <span style={{ fontWeight: 300 }}>{this.format(this.state.interacao)}</span></Label>
                </Row>
              </>) : (<></>)}

              {this.state.composicao_concentracoes ? (<>
                <Row className="mb-3">
                  <Label className="form-label">Composição e Concentrações : <span style={{ fontWeight: 300 }}>{this.format(this.state.composicao_concentracoes)}</span></Label>
                </Row>
              </>) : (<></>)}
              
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={this.controlarModal}
                color="light"
                className="waves-effect"
              >
                  Fechar
              </Button>
            </ModalFooter>
          </Modal>
      </React.Fragment>
    )
  }
}

export default Eletrolitos;